import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import EditText from "../../components/common/EditText";
import Button from "../../components/common/Button";
import Row from "../../components/common/Row";
import Col from "../../components/common/Col";
import Link from "../../components/common/Link";
import Dockington from "../../components/common/Dockington";

const MIN_COMPANY_LENGTH = 3;
const SIGN_UP_API = "https://app.dockington.com/api/signup"

export const validateEmail = function (email) {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email);
}

class SignUpForm extends Component {
    constructor(props) {
        super(props);

        this.captchaRef = React.createRef();

        this.state = {
            name: "",
            company: "",
            email: "",
            companyLogin: "",
        };
    }

    componentWillUnmount() {
        this.captchaRef.current.reset();
    }

    onChange = (key) => {
        switch (key) {
            case "company":
                return (company) => {
                    let companyLogin = company.toLowerCase().replace(/[^a-z]/g, "");
                    this.setState({ company, companyLogin });
                }
            default:
                return (value) => {
                    this.setState({ [key]: value });
                }
        }
    }

    onSubmit = (ev) => {
        ev.preventDefault();
        let error;
        const email = this.state.email.trim();
        const name = this.state.name.trim();
        if (!email) {
            if (!error) { error = {}; }
            error.email = this.props.translate("common.forms.error.emailRequired");
        } else if (!validateEmail(email)) {
            if (!error) { error = {}; }
            error.email = this.props.translate("common.forms.error.emailInvalid");
        }
        if (!name) {
            if (!error) { error = {}; }
            error.name = this.props.translate("common.forms.error.nameRequired");
        }
        if (!this.state.companyLogin || this.state.companyLogin.length < MIN_COMPANY_LENGTH) {
            if (!error) { error = {}; }
            error.company = this.props.translate("common.forms.error.companyRequired");
        }
        if (!error) {
            if (window.gtag) {
                window.gtag('event', "signup-accept", {
                    'event_category' : 'signup-form',
                    'event_label' : "Click on sign up"
                });
            }
            this.captchaRef.current.execute();
        } else {
            if (window.gtag) {
                window.gtag('event', "signup-fail", {
                    'event_category' : 'signup-form',
                    'event_label' : "Sign up failed validation"
                });
            }
            this.setState({ error });
        }
    }
    
    onCaptcha = (captcha) => {
        if (!captcha) {
            this.captchaRef.current.reset();
        } else {
            axios.post(SIGN_UP_API, {
                token: captcha,
                companyName: this.state.company.trim(),
                company: this.state.companyLogin.trim(),
                name: this.state.name.trim(),
                email: this.state.email.trim(),
            })
            .then(() => {
                window.location.replace("/thankyou");
            })
            .catch((err) => {
                this.captchaRef.current.reset();
                if (err.response) {
                    let error;
                    switch (err.response.status) {
                        case 412: // Email or companyLogin already in use
                            error = Object.assign({}, this.state.error, { signup: this.props.translate("signup.form.error.nameInUse") });
                            break;
                        case 500: // Internal error
                        case 400: // Bad input - Should never happen
                        case 401: // Captcha verification failed
                        default:
                            error = Object.assign({}, this.state.error, { signup: this.props.translate("signup.form.error.internal") });
                            break;
                    }
                    this.setState({ error });
                } else {
                    this.setState({
                        error: Object.assign({}, this.state.error, { signup: this.props.translate("signup.form.error.connection") }),
                    });
                }
            });
        }
    }

    render() {
        return (
            <div>
                <div style={{ position: "absolute", width: "100%" }}>
                    <div style={{ height: "120px", width: "100%", backgroundColor: "white" }}/>
                    <div style={{ height: "3em", width: "100%", backgroundColor: "#FFC107" }}/>
                </div>
                <Row justify="center">
                    <Col className="signup-form" grow={1}>
                        <h2 className="text-center small mb-2">{this.props.translate("signup.form.title").map((part) => (part.dockington ? <Dockington /> : part))}</h2>
                        <form onSubmit={this.onSubmit} method="post">
                            <EditText
                                id="name"
                                name="name"
                                label={this.props.translate("signup.form.name.label")}
                                placeholder={this.props.translate("signup.form.name.placeholder")}
                                maxLength={128}
                                required
                                error={this.state.error ? this.state.error.name : undefined}
                                onChange={this.onChange("name")}
                            />
                            <EditText
                                id="company"
                                name="company"
                                label={this.props.translate("signup.form.company.label")}
                                labelPostfix={this.state.companyLogin ? this.props.translate("signup.form.company.comment") + ` ${this.state.companyLogin}` : undefined}
                                placeholder={this.props.translate("signup.form.company.placeholder")}
                                maxLength={128}
                                required
                                error={this.state.error ? this.state.error.company : undefined}
                                onChange={this.onChange("company")}
                            />
                            <EditText
                                id="email"
                                name="email"
                                label={this.props.translate("signup.form.email.label")}
                                placeholder={this.props.translate("signup.form.email.placeholder")}
                                type="email"
                                error={this.state.error ? this.state.error.email : undefined}
                                maxLength={254}
                                required
                                onChange={this.onChange("email")}
                            />
                            {this.state.error && (this.state.error.signup || this.state.error.internal) &&
                            <p className="small text-danger">
                                {this.state.error.signup || this.state.error.connection}
                            </p>}
                            <Row justify="center" className="pt-1">
                                <Button
                                    success
                                    submit
                                    lg
                                >
                                    {this.props.translate("signup.form.yes")}
                                </Button>
                            </Row>
                            <Row justify="center" className="py-1">
                                <Link to="/" onClick={() => {
                                    if (window.gtag) {
                                        window.gtag('event', "signup-cancel", {
                                            'event_category' : 'signup-form',
                                            'event_label' : "Cancel sign up"
                                        });
                                    }
                                }}>
                                    <Button danger sm>
                                        {this.props.translate("signup.form.no")}
                                    </Button>
                                </Link>
                            </Row>
                            <p className="consent">{this.props.translate("signup.form.consent").map((part) => (part.href ? <a href={part.href} target="_blank">{part.text}</a> : part))}</p>
                            <ReCAPTCHA
                                sitekey="6LeCtZoUAAAAAEHPDCU6E54jCn4Y1aISDF_3yidB"
                                size="invisible"
                                onChange={this.onCaptcha}
                                ref={this.captchaRef}
                            />
                        </form>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default SignUpForm;
