import React from "react";
import RequiredLabel from "./RequiredLabel";
import { cn } from "../../util";

export interface EditTextProps {
    label?: string,
    labelPostfix?: string,
    value?: string,
    type?: "text" | "number" | "email" | "password",
    id?: string,
    name?: string,
    maxLength?: number,
    title?: string,
    placeholder?: string,
    required?: boolean,
    error?: string,
    className?: string,
    style?: React.CSSProperties,
    onChange: (ev: string) => void,
    prefix?: React.ReactText | React.ReactElement<any>,
    postfix?: React.ReactText | React.ReactElement<any>,
    description?: React.ReactText | React.ReactElement<any>,
}

const EditText: React.FunctionComponent<EditTextProps> = function (props) {
    return (
        <div className="form-group" style={props.style}>
            {props.label && <label htmlFor="edittext">{props.label}{props.required && <RequiredLabel />} {props.labelPostfix && <span className="ml-1 small text-muted">{props.labelPostfix}</span>}</label>}
            {(props.prefix || props.postfix) ?
                <div className="input-group">
                    {props.prefix && <div className="input-group-addon">{props.prefix}</div>}
                    <input
                        className={cn("form-control", props.error ? "is-invalid" : "", props.className)}
                        id={props.id || "edittext"}
                        name={props.name}
                        type={props.type || "text"}
                        maxLength={props.maxLength}
                        title={props.title}
                        placeholder={props.placeholder}
                        required={props.required}
                        value={props.value}
                        onChange={(ev) => { props.onChange(ev.target.value); }}
                    />
                    {props.postfix && <div className="input-group-addon">{props.postfix}</div>}
                </div>
            :
                <input
                    className={cn("form-control", props.error ? "is-invalid" : "", props.className)}
                    id={props.id || "edittext"}
                    name={props.name}
                    type={props.type || "text"}
                    maxLength={props.maxLength}
                    title={props.title}
                    placeholder={props.placeholder}
                    required={props.required}
                    value={props.value}
                    onChange={(ev) => { props.onChange(ev.target.value); }}
                />
            }
            {props.error && <div className="invalid-feedback">
                {props.error}
            </div>}
            {props.description &&
            <small className="form-text text-muted">
                {props.description}
            </small>}
        </div>
    )
}

export default EditText;
