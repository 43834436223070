import React from 'react';

export interface RequiredLabelProps {
    oneOf?: number | boolean,
}

const RequiredLabel = function (props: RequiredLabelProps) {
    return (
        <span style={{ color: props.oneOf ? "gray" : "red" }}> {typeof props.oneOf === "number" ? "*".repeat(props.oneOf) : "*"}</span>
    )
};

export default RequiredLabel;
